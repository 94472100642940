export enum Experiment {
  EnableOOIInEditor = 'specs.membersArea.framelessEditorPW',
  PrivateMemberIndicator = 'specs.membersArea.PrivateMemberIndicator',
  ShowRoleLabelToggle = 'specs.profileCardOOI.roleLabelToggle',
  RateFromAppSettings = 'specs.membersArea.rateFromAppSettings',
  TitleForAll = 'specs.profileCardOOI.TitleForAll',
  UseNewBlockedEmptyState = 'specs.profileCardOOI.useNewBlockedEmptyState',
  ReorganizeActionButtons = 'specs.profileCardOOI.ReorganizeActionButtons',
  MoveJoinCommunityCTA = 'specs.profileCardOOI.JoinCommunityCTAUnderMoreActions',
  EnableNewResetSettings = 'specs.profileCardOOI.NewResetSettings',
  EnableNewButtonSettings = 'specs.profileCardOOI.NewButtonSettings',
  EnableProfileDetailsEdit = 'specs.profileCardOOI.EnableProfileDetailsEdit',
}
